import type { JSX } from 'react';
import { CSSProperties, useContext, useEffect, useRef } from 'react';

import { BusinessTypes, KeyMapping } from '@canalplus/oneplayer-constants';
import { secToFormatHours } from '@canalplus/oneplayer-utils';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels } from '../../hooks';
import useMiniProgressBar from '../../hooks/useMiniProgressBar';
import { Slider } from '../slider/Slider';
import { createOnKeyHandler } from './progressBarUtils';

import styles from './TvMiniProgressBar.css';

const {
  TV_KEY_MAPPING,
  KEYS: { LEFT, RIGHT, ENTER, PLAYPAUSE },
} = KeyMapping;

const {
  VARIANTS: { MYCANAL },
} = BusinessTypes;

export interface IProps {
  variant?: string;
  onSeek?: () => void | undefined;
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
}

/**
 *
 * @param arg0 TvMiniProgressBar component props
 * @param arg0.variant the variant
 * @param arg0.onSeek callback to execute when seeking is requested
 * @param arg0.onPlay callback to execute when play is requested
 * @param arg0.onPause callback to execute when pause is requested
 * @returns progress bar which is synchronised with the player used and allows the user to
 * navigate into the stream.
 */
const TvMiniProgressBar = ({
  variant = MYCANAL,
  onSeek,
  onPlay,
  onPause,
}: IProps): JSX.Element | null => {
  const sliderRef = useRef<HTMLInputElement | null>(null);

  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const { duration, currentPosition, onDrag, onMouseDown, onMouseUp } =
    useMiniProgressBar({
      minimalPlayer,
      onKey: createOnKeyHandler([
        { action: 'backward', keys: TV_KEY_MAPPING[LEFT] },
        { action: 'forward', keys: TV_KEY_MAPPING[RIGHT] },
        {
          action: 'playpause',
          keys: [...TV_KEY_MAPPING[ENTER], ...TV_KEY_MAPPING[PLAYPAUSE]],
        },
      ]),
      onSeek,
      onPlay,
      onPause,
    });

  const ariaLabels = useAriaLabels(minimalPlayer, ['ProgressBar']);

  // Samsung TV uses Chrome < 79 which doesn't support CSS Math function
  const currentTimeStyle: CSSProperties = {
    ...(currentPosition !== null &&
      duration && {
        left: `${(currentPosition / duration) * 100}%`,
      }),
    transform: 'translate(-50%, 0)',
  };

  useEffect(() => {
    if (duration !== null && currentPosition !== null && sliderRef.current) {
      const positionInPercentage = (currentPosition / duration) * 100;
      const actionColor = styles[`actionColor--${variant}`];
      const white = styles.colorWhite30;
      sliderRef.current.style.background = `linear-gradient(to right, ${actionColor} 0%, ${actionColor} ${positionInPercentage}%, ${white} ${positionInPercentage}%, ${white} 100%)`;
    }
  }, [duration, currentPosition, sliderRef, variant]);

  if (duration === null || currentPosition === null) {
    return null;
  }

  return (
    <div className={styles.progressBarWrapper}>
      <Slider
        ariaLabel={ariaLabels.ProgressBar}
        ref={sliderRef}
        minValue={0}
        maxValue={duration}
        value={currentPosition}
        isTv
        onDrag={onDrag}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      />
      <div className={styles.timeDisplayBar}>
        {(currentPosition / duration) * 100 <= 90 && (
          <div className={styles.position} style={currentTimeStyle}>
            {secToFormatHours(currentPosition)}
          </div>
        )}
        <div className={styles.duration}>
          {`-${secToFormatHours(duration - currentPosition)}`}
        </div>
      </div>
    </div>
  );
};

export { TvMiniProgressBar };
